import {useEffect, useState} from "react";
import numeral from "numeral";
import {connect, useSelector} from "react-redux";
import * as api from "../../../api/api";

const UserDeposit = (props) => {
    const {user} = useSelector((props) => props.account);
    const [deposit, setDeposit] = useState(0)
    const [depositList, setDepositList] = useState([])

    useEffect(() => {
        getUserDepositList()
    }, [])

    const onMoneyInput = (money) => {
        if (money === 0) {
            setDeposit(money)
            return
        }
        setDeposit(prev => prev + money)
    }

    const getUserDepositList = () => {
        const params = {
            status: 0,
            userId: user.userId
        }
        api.getDepositList(params).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                setDepositList(data)
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {

            })
    }

    const requestDeposit = () => {
        console.log("### 이전 신청건수 :: ", depositList.length)
        if (depositList.length > 0) {
            window.alert("이전에 입금 신청한 건이 있습니다.")
            return
        }

        if (window.confirm("입금 신청을 하시겠어요?")) {
            const params = {
                userId: user.userId,
                userName: user.bankOwner,
                amount: deposit,
                status: 0
            }

            api.putDeposit(params).then(result => {
                const {data, status, statusText} = result
                if (status === 200) {
                    window.alert("입금신청이 정상적으로 완료되었습니다.")
                    return
                }
                else {
                    window.alert(statusText)
                }
            })
                .catch(ex => {
                    window.alert(ex.message)
                })
                .finally(() => {

                })
        }
    }

    return (
        <div className={"w-[1140px] flex flex-col bg-white"}>
            <img src={"https://14-ca.com/img/lang/kr/img_title_charge1.jpg"} alt={""}/>
            <div className={"flex flex-row p-2 justify-between border-b"}>
                <div className={"flex flex-row"}>
                    <span className={"text-sm text-gray-500"}>충전 >> 보유머니 충전</span>
                </div>
            </div>
            <div className={"flex flex-col gap-1 p-3 border-b"}>
                <span className={"text-lg"}>i 확인 / 필독사항</span>
                <span className={"text-sm text-blue-500"}>반드시 등록된 계좌를 통해 입금 바랍니다.</span>
                <span className={"text-sm text-gray-500"}>은행 기관을 통한 점검 시간에는 입금이 불가하므로 해당 은행 점검시간을 피해 입금을 해주시기 바랍니다.</span>
                <span className={"text-sm text-gray-500"}>입금 계좌는 수시로 변경될 수 있으니 꼭 입금 전 계좌 문의 후 입금 계좌 확인 후에 입금 바랍니다.</span>
                <span className={"text-sm text-gray-500"}>그 밖에 전용 계좌 발급 및 문의사항은 고객센터를 통해 문의 바랍니다.</span>
            </div>
            <div className={"flex flex-col gap-1 p-3 border-b"}>
                <span className={"text-lg"}>입금 계좌 정보 확인</span>
                <span className={"text-sm text-gray-500"}>입금 계좌번호는 쪽지로 발송되며, 계좌번호로 선 입금 바랍니다.</span>
            </div>
            <div className={"flex flex-col gap-4 p-3"}>
                <div className={"flex flex-row items-center gap-2"}>
                    <span className={"text-sm"}>계좌번호 문의</span>
                    <button className={"rounded text-sm text-white px-2 py-1 bg-green-500"}>계좌번호 신청</button>
                    <span className={"text-sm"}>계좌번호는 쪽지로 발송됩니다.</span>
                </div>
                <span className={"text-sm text-gray-500"}>위 안내된 계좌번호로 선입금 후 입금하신 금액을 정확히 입력하고 신청하기 버튼을 눌러주세요.</span>
                <div className={"flex flex-row gap-2"}>
                    <span className={"text-sm text-gray-500"}>현재 보유머니</span>
                    <span className={"text-sm text-green-500 font-bold"}>{numeral(user.userCash).format("0,0")} 원</span>
                </div>
                <div className={"flex flex-row gap-4"}>
                    <span className={"text-sm text-gray-500"}>충전금액 입력</span>
                    <div className={"flex flex-col gap-2"}>
                        <input className={"w-80 px-1 py-1 rounded-sm bg-white border border-yellow-200 text-black text-xs"}
                               value={numeral(deposit).format("0,0")}
                               onChange={event => {
                                    setDeposit(event.target.value)
                               }}
                               placeholder={"직접 입력은 이곳에 숫자만 입력해주세요."}/>
                        <div className={"flex flex-row gap-1"}>
                            <button className={"px-4 py-1 text-sm text-white font-bold bg-gray-500 rounded"} onClick={() => onMoneyInput(10000)}>1만원</button>
                            <button className={"px-4 py-1 text-sm text-white font-bold bg-gray-500 rounded"} onClick={() => onMoneyInput(30000)}>3만원</button>
                            <button className={"px-4 py-1 text-sm text-white font-bold bg-gray-500 rounded"} onClick={() => onMoneyInput(50000)}>5만원</button>
                            <button className={"px-4 py-1 text-sm text-white font-bold bg-green-500 rounded"} onClick={() => onMoneyInput(100000)}>10만원</button>
                            <button className={"px-4 py-1 text-sm text-white font-bold bg-green-500 rounded"} onClick={() => onMoneyInput(300000)}>30만원</button>
                            <button className={"px-4 py-1 text-sm text-white font-bold bg-green-500 rounded"} onClick={() => onMoneyInput(500000)}>50만원</button>
                            <button className={"px-4 py-1 text-sm text-white font-bold bg-green-500 rounded"} onClick={() => onMoneyInput(1000000)}>100만원</button>
                            <button className={"px-4 py-1 text-sm text-white font-bold bg-amber-500 rounded"} onClick={() => onMoneyInput(0)}>정정</button>
                        </div>
                    </div>
                </div>
                <div className={"flex flex-col items-center my-5"}>
                    <button className={"w-[400px] py-3 bg-zinc-700 text-white font-bold rounded"} onClick={requestDeposit}>보유머니 충전 신청하기</button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        account
    }
}

export default connect(mapStateToProps, null)(UserDeposit)