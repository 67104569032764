
export const MENU_BETTING = 1
export const MENU_CUSTOMER = 2
export const MENU_BOARD = 3
export const MENU_BETTING_HISTORY = 4
export const MENU_BETTING_RULE = 5
export const MENU_GAME_GUIDE = 51
export const MENU_BETTING_RESULT = 52
export const MENU_NOTICE = 6
export const MENU_CHARGE = 91
export const MENU_WITHDRAW = 92
export const MENU_EXCHANGE_POINT = 93
export const MENU_ATTENDANCE = 94