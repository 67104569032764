import {createStore, combineReducers} from 'redux'
import accountReducer from "../redux/actions/account"

const initialState = {
    sidebarShow: true
}

const changeState = (state = initialState, {type, ...rest}) => {
    switch (type) {
        case 'set':
            return {...state, ...rest}
        default:
            return state
    }
}

const rootReducer = combineReducers({
    state: changeState,
    account: accountReducer
})

const store = createStore(rootReducer)
export default store