import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {CModal} from "@coreui/react";
import Select from "react-select";
import * as api from "../../api/api";
import sha256 from "sha256";
import Loading from "../../component/Loading";
import UserJoin from "./UserJoin";
import {accountActions} from "../../redux/actions/account";

const Login = (props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userJoinOpen, setUserJoinOpen] = useState(false);
    const [loginInfo, setLoginInfo] = useState({
        userId: "", password: "", type: "A"
    })
    const templates = [
        {value: "A", label: "A Type"},
        {value: "B", label: "B Type"},
        {value: "C", label: "C Type"},
    ]

    const accept = () => {
        const {userId, password} = loginInfo;
        if (userId.length === 0 || password.length === 0) {
            return;
        }

        const params = {
            userId: userId,
            // password: sha256(password)
            password: password
        }

        setLoading(true);
        api.login(params).then(result => {
            const {responseCode, responseMessage, responseBody} = result.data;
            if (responseCode === "200") {
                // 로그인 타입 확인
                props.setAccount(responseBody);

                if (loginInfo.type === 'A') {
                    navigate("/a/main");
                }
                else if (loginInfo.type === 'B') {
                    navigate("/b/main");
                }
                else if (loginInfo.type === 'C') {
                    navigate("/c/main");
                }
            }
            else {
                window.alert(responseMessage);
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <div className="h-screen bg-black flex-1 flex-col items-center justify-center px-6 py-12 lg:px-8">
            {loading ? <Loading /> : null}
            <div className="flex flex-col mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <span className={"text-white mb-8"}>[Service Logo]</span>
                <form className="space-y-2" action="#" method="POST">
                    <label htmlFor="email"
                           className="block text-md font-bold leading-6 text-gray-200">
                        템플릿 형태
                    </label>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={templates[0]}
                        isDisabled={false}
                        name="color"
                        options={templates}
                        onChange={e => {
                            setLoginInfo((state) => ({
                                ...state, type: e.value
                            }))
                        }}
                    />
                    <div>
                        <label htmlFor="email"
                               className="block text-md font-bold leading-6 text-gray-200">
                            사용자 ID
                        </label>
                        <div className="mt-2">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                value={loginInfo.userId}
                                onChange={event => {
                                    setLoginInfo((state) => ({
                                        ...state, userId: event.target.value
                                    }))
                                }}
                                required
                                className="block w-full rounded-md border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div>
                        <div className="flex items-center justify-between">
                            <label htmlFor="password"
                                   className="block text-md font-bold leading-6 text-gray-200">
                                비밀번호
                            </label>
                        </div>
                        <div className="mt-2">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                value={loginInfo.password}
                                onChange={event => {
                                    setLoginInfo((state) => ({
                                        ...state, password: event.target.value
                                    }))
                                }}
                                required
                                className="block w-full rounded-md border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div>
                        <button type="submit"
                                onClick={accept}
                                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                            로그인
                        </button>
                    </div>
                </form>

                <div className="mt-10 text-center text-sm text-gray-500" onClick={() => {
                    setUserJoinOpen(true)
                }}>
                    <span className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                        회원가입
                    </span>
                </div>
            </div>
            <CModal size={"lg"} backdrop={"static"} alignment={"center"} visible={userJoinOpen} onClose={() => {
                setUserJoinOpen(false)
            }}>
                <UserJoin title={"회원가입 신청"}
                          content={"회원가입"}
                          onClose={() => {
                              setUserJoinOpen(false);
                          }} />
            </CModal>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAccount: (account) => {
            dispatch(accountActions.setAccount(account));
        },
        dispatch
    }
}

export default connect(null, mapDispatchToProps)(Login)