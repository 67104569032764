import { XCircleIcon } from "@heroicons/react/24/outline";

const BettingRow = (props) => {
    const {idx, item} = props

    return (
        <div className={"flex flex-col p-2 rounded bg-[#222a33]"}>
            <div className={"flex flex-row gap-1 justify-between"}>
                <div className={"flex flex-row gap-1"}>
                    <span className={"px-2 py-1 rounded-1 bg-blue-500 text-xs text-white font-bold"}>
                    {item.sport}
                    </span>
                    <span className={"text-xs text-white py-1"}>{item.league_name}</span>
                </div>
                <button className={"rounded text-xs font-bold text-white"} onClick={() => props.onDelete(idx)}>
                    <XCircleIcon className="h-4 w-4 text-white" />
                </button>
            </div>
            <div className={"flex flex-row gap-1"}>
                <span className={"text-xs text-white py-1"}>{item.market_name}</span>
                /
                <span className={"text-xs text-white py-1"}>{item.odd_name}</span>
                /
                <span className={"text-xs text-blue-500 font-bold py-1"}>{item.odd_value}</span>
            </div>
        </div>
    )
}

export default BettingRow