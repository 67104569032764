import {changeSportName} from "../../biz/Betting";

const WidgetBoardRow = (props) => {
    const {notice} = props
    const {instTime, subject} = props.data

    return (
        <div className={"flex flex-row p-1 border-b border-yellow-100 items-center justify-center"}>
            <div className={"flex flex-div w-full gap-2"}>
                * <span className={"text-sm text-white"}>{subject}</span>
            </div>
            {!notice &&
            <span className={"w-[120px] px-2 text-sm text-white"}>{instTime}</span>
            }
        </div>
    )
}

export default WidgetBoardRow