const INIT_ACCOUNT = "@@INIT_ACCOUNT"
const SET_ACCOUNT = "@@SET_ACCOUNT"
const SET_MY_IP = "@@SET_MY_IP"

const initialState = {};

function initAccount() {
  return {
    type: INIT_ACCOUNT
  };
}

function applyInitAccount() {
  return {};
}

function setAccount(user) {
  return {
    type: SET_ACCOUNT,
    user
  };
}

function applySetAccount(state, action) {
  const { user } = action;
  return {
    ...state,
    user
  };
}

function setMyIp(ip) {
  return {
    type: SET_MY_IP,
    ip
  }
}

function applySetMyIp(state, action) {
  const { ip } = action
  return {
    ...state,
    ip
  }
}

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
    case INIT_ACCOUNT:
      return applyInitAccount();
    case SET_ACCOUNT:
      return applySetAccount(state, action);
    case SET_MY_IP:
      return applySetMyIp(state, action)
  }
}

const actionCreator = {
  initAccount,
  setAccount,
  setMyIp
};

export { actionCreator as accountActions };

export default reducer;
