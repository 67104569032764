import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import * as api from "../../api/api";

const Splash = () => {
    const navigate = useNavigate()

    useEffect(() => {
        getSiteInspect()
    }, [])

    const getSiteInspect = () => {
        const siteId = "game"

        api.getSiteInspection(siteId).then(result => {
            const {data, status, statusText} = result;
            if (status === 200) {
                // 점검 상태 체크
                if (Object.keys(data).length === 0) {
                    // 정상
                    navigate("/main")
                }
                else {
                    if (data.status === 1) {
                        navigate("/inspect", {state: data})
                    }
                    else if (data.status === 0) {
                        navigate("/main")
                    }
                }
            }
        })
    }
}

export default Splash