import numeral from "numeral";

const WidgetWeekWithdrawRow = (props) => {
    const {no, time, amount, userId} = props.data

    return (
        <div className={"flex flex-row p-1 gap-2"}>
            <span className={"w-[50px] text-sm text-center text-white"}>{no}</span>
            <span className={"w-[200px] text-sm text-center text-white"}>{time}</span>
            <span className={"w-full text-end text-sm text-yellow-500 font-bold"}>{numeral(amount).format("0,0")}원</span>
            <span className={"w-[120px] text-end text-xs text-gray-500"}>{userId}</span>
        </div>
    )
}

export default WidgetWeekWithdrawRow