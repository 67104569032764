import React, {useEffect, useState} from "react";
import ic_close from "../../assets/ic-close.svg";
import * as api from "../../api/api";

const UserJoin = (props) => {
    const defaultUser = {
        companyCode: "0000", companyType: "0000", userId: "", password: "", userType: "6000",
        parentId: "test", mobileNo: "", userName: "", nickName: "", bankAccount: "", exchangePass: "",
        status: 9
    }
    const [isUserIdCheck, setIsUserIdCheck] = useState(false)
    const [joinUser, setJoinUser] = useState(defaultUser)

    useEffect(() => {

    })

    const onClose = () => {
        props.onClose();
    }

    const getGUser = (userId) => {
        if (joinUser.userId.length === 0) {
            window.alert("ID를 입력해주세요.")
            return
        }

        api.getGUser(userId).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                if (data.length === 0) {
                    window.alert("사용할 수 있는 ID 입니다.")
                    setIsUserIdCheck(true)
                }
                else {
                    window.alert("이미 사용중인 ID 입니다.")
                    setIsUserIdCheck(false)
                }
            }
            else {
                window.alert(statusText)
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {

            })
    }

    const onUserRegister = () => {
        // 아이디 중복체크 실행여부
        if (!isUserIdCheck) {
            window.alert("아이디 중복체크를 먼저 진행 해주세요.")
            return
        }

        if (window.confirm("가입신청 하시겠어요?")) {
            api.putGUser(joinUser).then(result => {
                const {data, status, statusText} = result
                if (status === 200) {
                    window.alert("등록이 완료되었습니다.")
                    props.onClose()
                }
                else {
                    window.alert(statusText)
                }
            })
                .catch(ex => {
                    window.alert(ex.message)
                })
                .finally(() => {

                })
        }
    }

    return (
        <div className={"bg-gray-800 py-[30px] rounded"}>
            <div className={"flex flex-row justify-end px-[36px]"}>
                <img src={ic_close} alt={""} onClick={onClose}/>
            </div>
            <p className={"text-white text-center text-4xl font-bold"}>{props.title}</p>

            <div className={"flex flex-col px-8 mt-8 gap-2"}>
                <div className={"flex flex-row text-white text-sm items-center gap-2"}>
                        <span className="w-[100px] font-bold text-gray-200">
                            사용자 ID
                        </span>
                    <input
                        id="userId"
                        name="userId"
                        type="userId"
                        value={joinUser.userId}
                        onChange={event => {
                            setJoinUser((state) => ({
                                ...state,
                                userId: event.target.value
                            }))
                        }}
                        required
                        className="rounded border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <button className={"rounded bg-red-500 p-2 text-white font-bold"}
                            onClick={() => getGUser(joinUser.userId)}>
                        중복확인
                    </button>
                </div>
                <div className={"flex flex-row text-white text-sm items-center gap-2"}>
                        <span className="w-[100px] font-bold text-gray-200">
                            비밀번호
                        </span>
                    <input
                        id="password"
                        name="password"
                        type="password"
                        value={joinUser.password}
                        onChange={event => {
                            setJoinUser((state) => ({
                                ...state,
                                password: event.target.value
                            }))
                        }}
                        required
                        className="rounded border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
                <div className={"flex flex-row text-white text-sm items-center gap-2"}>
                        <span className="w-[100px] font-bold text-gray-200">
                            닉네임
                        </span>
                    <input
                        id="nickName"
                        name="nickName"
                        value={joinUser.nickName}
                        onChange={event => {
                            setJoinUser((state) => ({
                                ...state,
                                nickName: event.target.value
                            }))
                        }}
                        required
                        className="rounded border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
                <div className={"flex flex-row text-white text-sm items-center gap-2"}>
                        <span className="w-[100px] font-bold text-gray-200">
                            전화번호
                        </span>
                    <input
                        id="mobileNo"
                        name="mobileNo"
                        value={joinUser.mobileNo}
                        onChange={event => {
                            setJoinUser((state) => ({
                                ...state,
                                mobileNo: event.target.value
                            }))
                        }}
                        required
                        className="rounded border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
                <button className={"rounded mt-4 p-2 bg-blue-900 text-lg text-white font-bold"}
                        onClick={onUserRegister}>
                    가입신청
                </button>
            </div>
        </div>
    )
}

export default React.memo(UserJoin);