import { useLocation } from "react-router-dom"

const Inspection = (props) => {
    const location = useLocation()
    const {inspectTitle, inspectBody} = location.state

    return (
        <div className={"flex items-center justify-center h-screen"}>
            <div className={"flex flex-col gap-2"}>
                <h1>{inspectTitle}</h1>
                <h5>{inspectBody}</h5>
            </div>
        </div>
    )
}

export default Inspection