import {useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import * as api from "../../../api/api";
import Loading from "../../../component/Loading";
import numeral from "numeral";

const MyBettingList = (props) => {
    const {user} = useSelector((props) => props.account);
    const [loading, setLoading] = useState(false)
    const [bettingList, setBettingList] = useState([])

    useEffect(() => {
        search()
    }, [])

    const winning = (result) => {
        switch (result) {
            case 0:
                return "대기"
            case 1:
                return "승"
            case 2:
                return "패"
            case 88:
                return "적특"
            case 99:
                return "취소"
        }
    }

    const search = () => {
        setLoading(true)
        api.getUserBettingList(user.userId).then(result => {
            const {data, status, statusText} = result;
            if (status === 200) {
                setBettingList(data)
            }
        })
            .catch(ex => {
                console.error(ex.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div className={"w-[1140px] flex flex-col bg-white"}>
            <img src={"https://14-ca.com/img/lang/kr/img_title_mypage.jpg"} alt={""}/>
            <div className={"flex flex-row p-2 justify-between"}>
                <div className={"flex flex-row"}>
                    <span className={"text-sm text-gray-500"}>마이페이지 >> 배팅내역</span>
                </div>
            </div>
            <div className={"flex-1"}>
                {loading ? <Loading/> : null}
                {bettingList.length > 0 &&
                <div className={"flex-glow"}>
                    {bettingList.map((betting, idx) => {
                        return (
                            <div key={String(idx)} className={"flex flex-col gap-2 rounded border-3 border-indigo-500 mb-4"}>
                                {betting.detailList.map((detail, idx) => {
                                    return (
                                        <div key={String(idx)} className={"flex flex-col gap-2"}>
                                            <div className={"flex flex-row bg-zinc-800 justify-between p-2"}>
                                                <div className={"flex flex-row gap-2 items-center"}>
                                                    <span className={"w-16 rounded-1 bg-indigo-500 text-xs text-white text-center px-1 py-1 font-bold"}>{detail.sport}</span>
                                                    <img className={"w-8 h-8"} src={detail.ccImage} alt={""}/>
                                                    <span className={"text-md text-white font-bold"}>{detail.ccKr}</span>
                                                </div>
                                                <div className={"flex flex-row gap-2 items-center"}>
                                                    <span className={"text-sm text-white"}>{betting.instTime}</span>
                                                </div>
                                            </div>
                                            {detail.resultDiv === '10' &&
                                            <div className={"flex flex-row p-2 gap-2 items-center"}>
                                                <span className={"w-[100px] text-md text-gray-800 font-bold"}>{detail.marketName}</span>
                                                <div className={"flex flex-1 flex-row justify-between gap-1"}>
                                                    <button className={`flex-1 rounded text-md text-gray-800 border-2 ${detail.oddsName === 'Home' ? 'bg-red-500 border-red-500 text-white' : null}`}>{detail.homeName}</button>
                                                    <button className={`w-20 rounded text-md text-gray-800 border-2 ${detail.oddsName === 'Draw' ? 'bg-red-500 border-red-500 text-white' : null}`}>Draw</button>
                                                    <button className={`flex-1 rounded text-md text-gray-800 border-2 ${detail.oddsName === 'Away' ? 'bg-red-500 border-red-500 text-white' : null}`}>{detail.awayName}</button>
                                                </div>
                                                <span className={`w-[50px] text-sm font-bold text-center ${detail.result === 1 ? 'text-green-500' : 'text-red-500'}`}>
                                                    {winning(detail.result)}
                                                </span>
                                            </div>
                                            }
                                            {detail.resultDiv === '20' &&
                                            <div className={"flex flex-row p-2 gap-2 items-center"}>
                                                <span className={"w-[100px] text-md text-gray-800 font-bold"}>{detail.marketName}</span>
                                                <div className={"flex flex-1 flex-row justify-between gap-1"}>
                                                    <button className={`flex-1 rounded text-md text-gray-800 border-2 ${detail.oddsName === 'Over' ? 'bg-red-500 border-red-500 text-white' : null}`}>{"오버"}</button>
                                                    <button className={`w-20 rounded text-md text-gray-800 border-2 ${detail.oddsName === 'Draw' ? 'bg-red-500 border-red-500 text-white' : null}`}>{detail.oddsBase}</button>
                                                    <button className={`flex-1 rounded text-md text-gray-800 border-2 ${detail.oddsName === 'Under' ? 'bg-red-500 border-red-500 text-white' : null}`}>{"언더"}</button>
                                                </div>
                                                <span className={`w-[50px] text-sm font-bold text-center ${detail.result === 1 ? 'text-green-500' : 'text-red-500'}`}>
                                                    {winning(detail.result)}
                                                </span>
                                            </div>
                                            }
                                            {detail.resultDiv === '30' &&
                                            <div className={"flex flex-row p-2 gap-2 items-center"}>
                                                <span className={"w-[100px] text-md text-gray-800 font-bold"}>{detail.marketName}</span>
                                                <div className={"flex flex-1 flex-row justify-between gap-1"}>
                                                    <button className={`flex-1 rounded text-md text-gray-800 border-2 ${detail.oddsName === 'Home' ? 'bg-red-500 border-red-500 text-white' : null}`}>{detail.homeName}</button>
                                                    <button className={`w-20 rounded text-md text-gray-800 border-2 ${detail.oddsName === 'Draw' ? 'bg-red-500 border-red-500 text-white' : null}`}>{detail.oddsBase}</button>
                                                    <button className={`flex-1 rounded text-md text-gray-800 border-2 ${detail.oddsName === 'Away' ? 'bg-red-500 border-red-500 text-white' : null}`}>{detail.awayName}</button>
                                                </div>
                                                <span className={`w-[50px] text-sm font-bold text-center ${detail.result === 1 ? 'text-green-500' : 'text-red-500'}`}>
                                                    {winning(detail.result)}
                                                </span>
                                            </div>
                                            }
                                        </div>
                                    )
                                })}
                                <div className={"h-1 border-1"} />
                                <div className={"flex flex-col p-2 gap-1"}>
                                    <div className={"flex flex-row"}>
                                        <span className={"text-xs font-bold"}>배팅시간 : {betting.instTime}</span>
                                        <span className={"text-xs font-bold px-2"}> / </span>
                                        <span className={"text-xs font-bold"}>배당율 : {betting.bettingOdds}</span>
                                        <span className={"text-xs font-bold px-2"}> / </span>
                                        <span className={"text-xs font-bold"}>배팅금액 : {numeral(betting.bettingAmount).format("0,0")}원</span>
                                    </div>
                                    <div className={"flex flex-row"}>
                                        <span className={"text-xs font-bold"}>예상 적중금액 : {numeral(betting.bettingAmount * betting.bettingOdds).format("0,0")} 원</span>
                                        <span className={"text-xs font-bold px-2"}> / </span>
                                        <span className={"text-xs font-bold"}>당첨금 : <span className={"text-red-500"}>{betting.result === 1 ? numeral(betting.bettingAmount * betting.bettingOdds).format("0,0") : 0} 원</span></span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) =>
{
    const {account} = state;
    return {
        account
    }
}

export default connect(mapStateToProps, null)(MyBettingList)