import {soccer} from "../../../assets/rule/soccer";

const BettingGuideSoccer = () => {
    return (
        <div className={"flex flex-col"}>
            {soccer.map((item, idx) => {
                return (
                    <div className={"flex flex-row border"} key={String(idx)}>
                        <span className={"w-[153px] py-2 text-sm text-center"}>{item.bettingType}</span>
                        <span className={"w-[76px] py-2 text-sm text-center"}>{item.timeType}</span>
                        <span className={"w-[864px] py-2 text-sm text-center"}>{item.rule}</span>
                    </div>
                )
            })}
        </div>
    )
}

export default BettingGuideSoccer