import {accountActions} from "../../../redux/actions/account";
import {connect} from "react-redux";
import {useState} from "react";
import sha256 from "sha256";
import * as api from "../../../api/api";
import {useNavigate} from "react-router-dom";
import Loading from "../../../component/Loading";

const UserLogout = (props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [loginInfo, setLoginInfo] = useState({
        userId: "", password: ""
    })

    const login = () => {
        const {userId, password} = loginInfo;
        if (userId.length === 0 || password.length === 0) {
            return;
        }

        const params = {
            userId: userId,
            // password: sha256(password)
            password: password
        }

        setLoading(true);
        api.login(params).then(result => {
            const {data, status, statusText} = result;
            if (status === 200) {
                // 사용자 정보 확인
                if (Object.keys(data).length > 0) {
                    if (data.useYn === 1) {
                        if (data.status === 9) {
                            // 아직 가입 대기상태
                            window.alert("회원 가입 대기상태 입니다. 고객센터에 문의하세요.")
                            return
                        }
                    }
                    else {
                        window.alert("사용할 수 없는 계정입니다. 고객센터에 문의하세요.")
                        return
                    }

                    // 로그인 타입 확인
                    props.setAccount(data)
                    props.setLogin(true)
                    props.onMenu(0)
                    // navigate("/")
                }
                else {
                    // 존재하지 않는 ID
                    window.alert("ID와 패스워드를 다시 확인해주세요.")
                    return
                }
            }
            else {
                window.alert(statusText)
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const register = () => {
        navigate("/register")
    }

    return (
        <div className={"bg-black flex flex-row justify-end p-2 gap-2 border-b-2 border-yellow-100"}>
            {loading ? <Loading /> : null}
            <input className={"w-32 px-1 rounded-sm bg-black border border-yellow-200 text-white text-xs"}
                   onChange={event => {
                       setLoginInfo((state) => ({
                           ...state, userId: event.target.value
                       }))
                   }}
                   onKeyPress={(e) => {
                       if (e.key === "Enter") {
                           login()
                       }
                   }}
                   placeholder={"아이디"}/>
            <input className={"w-32 px-1 rounded-sm bg-black border border-yellow-200 px-2 text-white text-xs"}
                   type={"password"}
                   onChange={event => {
                       setLoginInfo((state) => ({
                           ...state, password: event.target.value
                       }))
                   }}
                   onKeyPress={(e) => {
                       if (e.key === "Enter") {
                           login()
                       }
                   }}
                   placeholder={"비밀번호"}/>
            <button className={"rounded-full px-3 py-1 text-white text-xs border border-yellow-200 hover:bg-amber-700"}
                    onClick={login}>로그인</button>
            <button className={"rounded-full px-3 py-1 text-white text-xs border border-yellow-200 hover:bg-amber-700"}
                    onClick={register}>회원가입</button>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAccount: (account) => {
            dispatch(accountActions.setAccount(account));
        },
        dispatch
    }
}

export default connect(null, mapDispatchToProps)(UserLogout)