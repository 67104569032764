const MenuButton = (props) => {
    const {id, img, title, desc, onClick} = props
    return (
        <div className={"flex flex-col w-[150px] h-[110px] rounded border-2 border-yellow-100 items-center justify-center"} onClick={() => onClick(id)}>
            <div className={"w-[60px] h-[60px]"}>
                <img src={img} alt={""}/>
            </div>
            <span className={"text-white font-bold text-sm"}>{title}</span>
            <span className={"text-gray-500 text-xs"}>{desc}</span>
        </div>
    )
}

export default MenuButton