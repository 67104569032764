import {connect, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import numeral from "numeral";
import * as api from "../../../api/api";

const UserWithdraw = (props) => {
    const {user} = useSelector((props) => props.account);
    const [amount, setAmount] = useState(0)
    const [password, setPassword] = useState("")
    const [withdrawList, setWithdrawList] = useState([])

    useEffect(() => {
        getUserWithdrawList()
    }, [])

    const onMoneyInput = (money) => {
        if (money === 0) {
            setAmount(money)
            return
        }
        setAmount(prev => prev + money)
    }

    const getUserWithdrawList = () => {
        const params = {
            status: 0,
            userId: user.userId
        }
        api.getWithdrawList(params).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                setWithdrawList(data)
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {

            })
    }

    const requestWithdraw = () => {
        // 비밀번호 비교
        if (password !== user.exchangePass) {
            window.alert("출금 비밀번호가 다릅니다.\n다시 확인해주세요")
            return
        }

        console.log("### 이전 신청건수 :: ", withdrawList.length)
        if (withdrawList.length > 0) {
            window.alert("이전에 출금 신청한 건이 있습니다.")
            return
        }

        if (window.confirm("출금 신청을 하시겠어요?")) {
            const params = {
                userId: user.userId,
                userName: user.bankOwner,
                amount: amount,
                status: 0,
                bankOwner: user.bankOwner,
                bankAccount: user.bankAccount,
                bankName: user.bankName
            }

            api.putWithdraw(params).then(result => {
                const {data, status, statusText} = result
                if (status === 200) {
                    window.alert("출금 신청이 정상적으로 완료되었습니다.")
                    return
                }
                else {
                    window.alert(statusText)
                }
            })
                .catch(ex => {
                    window.alert(ex.message)
                })
                .finally(() => {

                })
        }
    }

    return (
        <div className={"w-[1140px] flex flex-col bg-white"}>
            <img src={"https://14-ca.com/img/lang/kr/img_title_charge1.jpg"} alt={""}/>
            <div className={"flex flex-row p-2 justify-between border-b"}>
                <div className={"flex flex-row"}>
                    <span className={"text-sm text-gray-500"}>환전 >> 보유머니 환전</span>
                </div>
            </div>
            <div className={"flex flex-col gap-1 p-3 border-b"}>
                <span className={"text-lg"}>i 확인 / 필독사항</span>
                <span className={"text-sm text-blue-500"}>반드시 등록된 계좌를 통해 출금 처리가 되며 등록된 계좌 외에는 절대 출금 처리가 불가합니다.</span>
                <span className={"text-sm text-gray-500"}>은행 기관을 통한 점검 시간에는 출금이 불가하므로 해당 은행 점검시간을 피해 출금을 해주시기 바랍니다.</span>
                <span className={"text-sm text-gray-500"}>그 밖에 문의사항은 고객센터를 통해 문의 바랍니다.</span>
            </div>
            <div className={"flex flex-col gap-1 p-3 border-b"}>
                <span className={"text-lg"}>환전 금액 입력</span>
                <span className={"text-sm text-gray-500"}>환전 신청을 완료하게 되면 보유머니에서 신청한 금액만큼 차감되며 고객님의 계좌번호로 입금됩니다.</span>

                <div className={"flex flex-col gap-4 mt-4"}>
                    <div className={"flex flex-row gap-4"}>
                        <span className={"text-sm text-gray-500"}>현재 보유머니</span>
                        <span className={"text-sm text-green-500 font-bold"}>{numeral(user.userCash).format("0,0")}원</span>
                    </div>
                    <div className={"flex flex-row gap-4"}>
                        <span className={"text-sm text-gray-500"}>환전금액 입력</span>
                        <input className={"w-80 px-1 py-1 rounded-sm bg-white border border-yellow-200 text-black text-xs"}
                               value={numeral(amount).format("0,0")}
                               onChange={event => {
                                   setAmount(event.target.value)
                               }}
                               placeholder={"직접 입력은 이곳에 숫자만 입력해주세요."}/>
                    </div>
                    <div className={"flex flex-row gap-4"}>
                        <span className={"text-sm text-gray-500"}>출금 비밀번호</span>
                        <div className={"flex flex-col gap-2"}>
                            <input className={"w-80 px-1 py-1 rounded-sm bg-white border border-yellow-200 text-black text-xs"}
                                   type={"password"}
                                   value={password}
                                   onChange={event => {
                                       setPassword(event.target.value)
                                   }}
                                   placeholder={"비밀번호를 입력하세요"}/>
                            <div className={"flex flex-row gap-1"}>
                                <button className={"px-4 py-1 text-sm text-white font-bold bg-gray-500 rounded"} onClick={() => onMoneyInput(10000)}>1만원</button>
                                <button className={"px-4 py-1 text-sm text-white font-bold bg-gray-500 rounded"} onClick={() => onMoneyInput(30000)}>3만원</button>
                                <button className={"px-4 py-1 text-sm text-white font-bold bg-gray-500 rounded"} onClick={() => onMoneyInput(50000)}>5만원</button>
                                <button className={"px-4 py-1 text-sm text-white font-bold bg-green-500 rounded"} onClick={() => onMoneyInput(100000)}>10만원</button>
                                <button className={"px-4 py-1 text-sm text-white font-bold bg-green-500 rounded"} onClick={() => onMoneyInput(300000)}>30만원</button>
                                <button className={"px-4 py-1 text-sm text-white font-bold bg-green-500 rounded"} onClick={() => onMoneyInput(500000)}>50만원</button>
                                <button className={"px-4 py-1 text-sm text-white font-bold bg-green-500 rounded"} onClick={() => onMoneyInput(1000000)}>100만원</button>
                                <button className={"px-4 py-1 text-sm text-white font-bold bg-green-500 rounded"} onClick={() => onMoneyInput(user.userCash)}>전액</button>
                                <button className={"px-4 py-1 text-sm text-white font-bold bg-amber-500 rounded"} onClick={() => onMoneyInput(0)}>정정</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"flex flex-col items-center my-5"}>
                    <button className={"w-[400px] py-3 bg-zinc-700 text-white font-bold rounded"} onClick={requestWithdraw}>보유머니 환전 신청하기</button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        account
    }
}

export default connect(mapStateToProps, null)(UserWithdraw)