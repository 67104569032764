import {useState} from "react";
import * as api from "../../api/api";
import {accountActions} from "../../redux/actions/account";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import backMovie from "../../assets/movie_1.mp4"

const UserRegister = (props) => {
    const navigate = useNavigate();
    const [isUserIdCheck, setIsUserIdCheck] = useState(false)
    const [userInfo, setUserInfo] = useState({
        companyCode: '0000', companyType: '0000', userType: "6000", parentId: "test", status: 9,
        userId: "", password: "", exchangePass: "", userName: "", nickName: "", mobileNo: "", recommendCode: "", bankOwner: "", bankName: "", bankAccount: ""
    })

    const getGUser = () => {
        if (userInfo.userId.length === 0) {
            window.alert("ID를 입력해주세요.")
            return
        }

        api.getGUser(userInfo.userId).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                if (data.length === 0) {
                    window.alert("사용할 수 있는 ID 입니다.")
                    setIsUserIdCheck(true)
                }
                else {
                    window.alert("이미 사용중인 ID 입니다.")
                    setIsUserIdCheck(false)
                }
            }
            else {
                window.alert(statusText)
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {

            })
    }

    const register = () => {
        // 아이디 중복체크 실행여부
        if (!isUserIdCheck) {
            window.alert("아이디 중복체크를 먼저 진행 해주세요.")
            return
        }

        if (window.confirm("가입신청 하시겠어요?")) {
            api.putGUser(userInfo).then(result => {
                const {data, status, statusText} = result
                if (status === 200) {
                    window.alert("등록이 완료되었습니다.")
                    props.onClose()
                }
                else {
                    window.alert(statusText)
                }
            })
                .catch(ex => {
                    window.alert(ex.message)
                })
                .finally(() => {

                })
        }
    }

    const cancel = () => {
        navigate(-1)
    }

    return (
        <div className={"relative h-screen w-full overflow-hidden"}>
            <video className={"absolute top-0 left-0 w-full h-full object-cover"}
                   src={backMovie}
                   autoPlay={true}
                   loop={true}
                   muted={true}/>
            <div className="absolute top-0 left-0 w-full h-full bg-black opacity-75" />
            <div className={"relative z-10 flex flex-row gap-4 h-screen items-center justify-center"}>
                <div className={"flex flex-col gap-4 justify-end items-end mt-8"}>
                    <span className={"text-gray-400 font-bold"}>아이디</span>
                    <span className={"text-gray-400 font-bold"}>비밀번호</span>
                    <span className={"text-gray-400 font-bold"}>환전 비밀번호</span>
                    <span className={"text-gray-400 font-bold"}>닉네임</span>
                    <span className={"text-gray-400 font-bold"}>휴대폰번호</span>
                    <span className={"text-gray-400 font-bold"}>추천인 아이디</span>
                    <span className={"text-gray-400 font-bold"}>환전계좌 설정</span>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                </div>
                <div className={"w-[1px] bg-gray-800 h-screen"} />
                <div className={"flex flex-col gap-2"}>
                    <div className={"flex flex-col mb-4"}>
                        <div className={"flex flex-row gap-1 items-baseline"}>
                            <span className={"text-lg text-white"}>One Solution 회원가입</span>
                            <span className={"text-xs text-slate-700 text-uppercase"}>member register</span>
                        </div>
                        <span className={"text-sm text-yellow-500"}>
                        회원가입 시 모든 항목을 정확하게 기재하시기 바랍니다. One Solution의 회원 데이터는 안전한 서버에서 안전하게 보관됩니다.
                    </span>
                    </div>
                    <div className={"flex flex-col gap-3"}>
                        <div className={"flex flex-row gap-2 items-center"}>
                            <input className={"rounded bg-zinc-700 text-white opacity-75 px-2 py-1 placeholder-zinc"}
                                   placeholder={"아이디"}
                                   onChange={(event) => {
                                       setUserInfo((state) => ({
                                           ...state, userId: event.target.value
                                       }))
                                   }}
                            />
                            <button className={"rounded bg-yellow-500 text-xs px-3 py-2"} onClick={getGUser}>중복확인</button>
                            <span className={"text-xs text-zinc-400"}>영문, 숫자만 입력 가능하며 최소 4자 이상 입력하세요.</span>
                        </div>
                        <div className={"flex flex-row gap-2 items-center"}>
                            <input className={"rounded bg-zinc-700 text-white opacity-75 px-2 py-1 placeholder-zinc"}
                                   placeholder={"비밀번호"}
                                   type={"password"}
                                   onChange={(event) => {
                                       setUserInfo((state) => ({
                                           ...state, password: event.target.value
                                       }))
                                   }}
                            />
                        </div>
                        <div className={"flex flex-row gap-2 items-center"}>
                            <input className={"rounded bg-zinc-700 text-white opacity-75 px-2 py-1 placeholder-zinc"}
                                   placeholder={"환전 비밀번호"}
                                   type={"password"}
                                   onChange={(event) => {
                                       setUserInfo((state) => ({
                                           ...state, exchangePass: event.target.value
                                       }))
                                   }}
                            />
                            <span className={"text-xs text-zinc-400"}>4~8자 내외로 입력해주세요. (특수문자 사용불가).</span>
                        </div>
                        <div className={"flex flex-row gap-2 items-center"}>
                            <input className={"rounded bg-zinc-700 text-white opacity-75 px-2 py-1 placeholder-zinc"}
                                   placeholder={"닉네임"}
                                   onChange={(event) => {
                                       setUserInfo((state) => ({
                                           ...state, nickName: event.target.value, userName: event.target.value
                                       }))
                                   }}
                            />
                            <span className={"text-xs text-zinc-400"}>한글, 영문, 숫자만 입력 가능하며, 최소 2자 이상을 입력하세요.</span>
                        </div>
                        <div className={"flex flex-row gap-2 items-center"}>
                            <input className={"rounded bg-zinc-700 text-white opacity-75 px-2 py-1 placeholder-zinc"}
                                   placeholder={"휴대폰번호"}
                                   onChange={(event) => {
                                       setUserInfo((state) => ({
                                           ...state, mobileNo: event.target.value
                                       }))
                                   }}
                            />
                            <span className={"text-xs text-zinc-400"}>숫자만 입력하세요.</span>
                        </div>
                        <div className={"flex flex-row gap-2 items-center"}>
                            <input className={"rounded bg-zinc-700 text-white opacity-75 px-2 py-1 placeholder-zinc"}
                                   placeholder={"가입코드"}
                                   onChange={(event) => {
                                       setUserInfo((state) => ({
                                           ...state, recommendCode: event.target.value
                                       }))
                                   }}
                            />
                        </div>
                        <div className={"flex flex-row gap-2 items-center"}>
                            <input className={"rounded bg-zinc-700 text-white opacity-75 px-2 py-1 placeholder-zinc"}
                                   placeholder={"예금주"}
                                   onChange={(event) => {
                                       setUserInfo((state) => ({
                                           ...state, bankOwner: event.target.value
                                       }))
                                   }}
                            />
                        </div>
                        <div className={"flex flex-row gap-2 items-center"}>
                            <input className={"rounded bg-zinc-700 text-white opacity-75 px-2 py-1 placeholder-zinc"}
                                   placeholder={"은행명"}
                                   onChange={(event) => {
                                       setUserInfo((state) => ({
                                           ...state, bankName: event.target.value
                                       }))
                                   }}
                            />
                            <input className={"rounded bg-zinc-700 text-white opacity-75 px-2 py-1 placeholder-zinc"}
                                   placeholder={"계좌번호 (숫자만 입력)"}
                                   onChange={(event) => {
                                       setUserInfo((state) => ({
                                           ...state, bankAccount: event.target.value
                                       }))
                                   }}
                            />
                        </div>
                    </div>
                    <div className={"flex flex-row mt-4 mb-4 gap-2"}>
                        <button className={"w-36 rounded-full border-2 text-sm border-zinc-700 py-2 text-white"} onClick={register}>가입하기</button>
                        <button className={"w-36 rounded-full border-2 text-sm border-zinc-700 py-2 text-white"} onClick={cancel}>취소</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAccount: (account) => {
            dispatch(accountActions.setAccount(account));
        },
        dispatch
    }
}

export default connect(null, mapDispatchToProps)(UserRegister)