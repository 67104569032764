import {useEffect, useState} from "react";
import WidgetWeekWithdrawRow from "./WidgetWeekWithdrawRow";

const WidgetWeekWithdraw = (props) => {
    const [scrolling, setScrolling] = useState(false);
    const [list, setList] = useState([
        {no: 1, time: '24/07/26 21:00', amount: 500000, userId: 'zxxxxxx'},
        {no: 2, time: '24/07/26 21:00', amount: 500000, userId: 'zxxxxxx'},
        {no: 3, time: '24/07/26 21:00', amount: 500000, userId: 'zxxxxxx'},
        {no: 4, time: '24/07/26 21:00', amount: 500000, userId: 'zxxxxxx'},
        {no: 5, time: '24/07/26 21:00', amount: 500000, userId: 'zxxxxxx'},
        {no: 6, time: '24/07/26 21:00', amount: 500000, userId: 'zxxxxxx'},
        {no: 7, time: '24/07/26 21:00', amount: 500000, userId: 'zxxxxxx'},
        {no: 8, time: '24/07/26 21:00', amount: 500000, userId: 'zxxxxxx'},
        {no: 9, time: '24/07/26 21:00', amount: 500000, userId: 'zxxxxxx'},
        {no: 10, time: '24/07/26 21:00', amount: 500000, userId: 'zxxxxxx'},
        {no: 11, time: '24/07/26 21:00', amount: 500000, userId: 'zxxxxxx'},
        {no: 12, time: '24/07/26 21:00', amount: 500000, userId: 'zxxxxxx'},
        {no: 13, time: '24/07/26 21:00', amount: 500000, userId: 'zxxxxxx'},
        {no: 14, time: '24/07/26 21:00', amount: 500000, userId: 'zxxxxxx'},
        {no: 15, time: '24/07/26 21:00', amount: 500000, userId: 'zxxxxxx'},
        {no: 16, time: '24/07/26 21:00', amount: 500000, userId: 'zxxxxxx'},
        {no: 17, time: '24/07/26 21:00', amount: 500000, userId: 'zxxxxxx'},
        {no: 18, time: '24/07/26 21:00', amount: 500000, userId: 'zxxxxxx'},
        {no: 19, time: '24/07/26 21:00', amount: 500000, userId: 'zxxxxxx'},
        {no: 20, time: '24/07/26 21:00', amount: 500000, userId: 'zxxxxxx'}
    ])

    useEffect(() => {
        const scrollInterval = setInterval(() => {
            setScrolling(prev => !prev);
        }, 1000); // Adjust the duration as needed (20 seconds for a complete cycle)

        return () => clearInterval(scrollInterval);
    }, []);

    return (
        <div className={"w-[550px] h-[300px] rounded-xl border-2 border-yellow-100 p-2"}>
            <div className={"flex flex-row justify-center"}>
                <span className={"font-bold text-xl text-yellow-200"}>금주의 출금 TOP</span>
            </div>
            <div className={"flex flex-col my-4"}>
                <div className={"scroll-container2"}>
                    <div className={`scroll-content2 ${scrolling ? 'scrolling' : ''}`}>
                        {list.map((item, idx) => {
                            return <WidgetWeekWithdrawRow key={String(idx)} data={item} />
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WidgetWeekWithdraw