import {useEffect, useState} from "react";
import {matches} from "../../assets/data/match";
import * as api from "../../api/api";

const Left = (props) => {
    const [loading, setLoading] = useState(false);
    const [contentsList, setContentsList] = useState([]);
    const [selectedMatch, setSelectedMatch] = useState(matches.data[0]);

    const onMatch = (match) => {
        setSelectedMatch(match);
        props.onSelectLeague(match);
        // getMatchList(match.id);
    }

    const getMatchList = (match) => {
        setLoading(true);
        api.getMatchList(match).then(res => {
            const {data, status, statusText} = res;
            if (status === 200) {
                const {success, result} = res.data
                if (success === 1) {
                    // 성공
                    if (result !== null) {
                        setContentsList(result.filter(item => item.id !== 0));
                        props.onSelectLeague(result.filter(item => item.id !== 0));
                    } else {
                        setContentsList([]);
                        props.onSelectLeague([]);
                    }
                }
                else {
                    // API 통신 실패
                    window.alert("API 서버 통신 실패");
                }
            }
            else {
                // API 통신 에러
                window.alert("API 서버 통신 에러");
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <div className={"flex flex-row p-2 bg-[#2a3340] drop-shadow-xl gap-4"}>
            {matches.data.map((match, idx) => {
                const isSelected = match === selectedMatch;
                return (
                    <div key={String(idx)}
                         className={`cursor-pointer px-2 ${isSelected ? "rounded bg-blue-500" : ""}`}
                         onClick={() => onMatch(match)}>
                        <span className={"text-white font-bold"}>{match.name}</span>
                    </div>
                )
            })}
        </div>
    )
}

export default Left;