export const soccer = [
    {bettingType: "승무패", timeType: "연장제외", rule: "승리가 예상되는 팀 또는 무승부를 선택하여 배팅하는 방식"},
    {bettingType: "승무패(전반전)", timeType: "연장없음", rule: "전반전만의 양팀이 득점한 경기결과로 승리가 예상되는 팀 또는 무승부를 선택하여 배팅하는 방식"},
    {bettingType: "승무패(후반전)", timeType: "연장없음", rule: "후반전만의 양팀이 득점한 경기결과로 승리가 예상되는 팀 또는 무승부를 선택하여 배팅하는 방식"},
    {bettingType: "승패(무X)", timeType: "연장없음", rule: "승리가 예상되는 팀을 선택하여 배팅하는 방식 (무승부 적중특례)"},
    {bettingType: "승패(무X)(전반전)", timeType: "연장없음", rule: "전반전만의 양팀이 득점한 경기결과로 승리가 예상되는 팀을 선택하여 배팅하는 방식 (무승부 적중특례)"},
    {bettingType: "승패(무X)(후반전)", timeType: "연장없음", rule: "후반전만의 양팀이 득점한 경기결과로 승리가 예상되는 팀을 선택하여 배팅하는 방식 (무승부 적중특례)"},
    {bettingType: "무실점 완승", timeType: "연장제외", rule: "무실점으로 승리가 예상되는 팀을 선택하여 배팅하는 방식 (상대팀 무득점이어야 함)"},
    {bettingType: "전반 & 전후반", timeType: "연장제외", rule: "전반전만의 결과와 최종(전후반포함) 각각의 양팀이 득점한 경기결과로 승리가 예상되는 팀 또는 무승부를 선택하여 배팅하는 방식"},
    {bettingType: "더블찬스", timeType: "연장제외", rule: "두개의 결과를 예측하여 배팅하는 방식"},
    {bettingType: "더블찬스(전반전)", timeType: "연장없음", rule: "전반전만의 양팀이 득점한 경기결과로 두개의 결과를 예측하여 배팅하는 방식"},
    {bettingType: "더블찬스(후반전)", timeType: "연장없음", rule: "후반전만의 양팀이 득점한 경기결과로 두개의 결과를 예측하여 배팅하는 방식"},
    {bettingType: "핸디캡", timeType: "연장제외", rule: "상대적으로 전력이 우수한 팀에게 불리한 조건을 주어 경기의 균형을 맞추고 승리가 예상되는 팀을 선택하여 배팅하는 방식 홈팀(왼쪽)기준으로 +,-의 핸디캡 기준점수가 표기됨"},
    {bettingType: "핸디캡(전반전)", timeType: "연장없음", rule: "핸디캡을 적용하여 전반전만의 양팀이 득점한 경기결과로 승리가 예상되는 팀을 선택하여 배팅하는 방식"},
    {bettingType: "핸디캡(후반전)", timeType: "연장없음", rule: "핸디캡을 적용하여 후반전만의 양팀이 득점한 경기결과로 승리가 예상되는 팀을 선택하여 배팅하는 방식"},
    {bettingType: "승무패", timeType: "연장제외", rule: "승리가 예상되는 팀 또는 무승부를 선택하여 배팅하는 방식"},
    {bettingType: "승무패", timeType: "연장제외", rule: "승리가 예상되는 팀 또는 무승부를 선택하여 배팅하는 방식"},
    {bettingType: "승무패", timeType: "연장제외", rule: "승리가 예상되는 팀 또는 무승부를 선택하여 배팅하는 방식"},
    {bettingType: "승무패", timeType: "연장제외", rule: "승리가 예상되는 팀 또는 무승부를 선택하여 배팅하는 방식"},
    {bettingType: "승무패", timeType: "연장제외", rule: "승리가 예상되는 팀 또는 무승부를 선택하여 배팅하는 방식"},
    {bettingType: "승무패", timeType: "연장제외", rule: "승리가 예상되는 팀 또는 무승부를 선택하여 배팅하는 방식"},
    {bettingType: "승무패", timeType: "연장제외", rule: "승리가 예상되는 팀 또는 무승부를 선택하여 배팅하는 방식"},
    {bettingType: "승무패", timeType: "연장제외", rule: "승리가 예상되는 팀 또는 무승부를 선택하여 배팅하는 방식"},
    {bettingType: "승무패", timeType: "연장제외", rule: "승리가 예상되는 팀 또는 무승부를 선택하여 배팅하는 방식"},
    {bettingType: "승무패", timeType: "연장제외", rule: "승리가 예상되는 팀 또는 무승부를 선택하여 배팅하는 방식"},
    {bettingType: "승무패", timeType: "연장제외", rule: "승리가 예상되는 팀 또는 무승부를 선택하여 배팅하는 방식"},
    {bettingType: "승무패", timeType: "연장제외", rule: "승리가 예상되는 팀 또는 무승부를 선택하여 배팅하는 방식"},
    {bettingType: "승무패", timeType: "연장제외", rule: "승리가 예상되는 팀 또는 무승부를 선택하여 배팅하는 방식"},
]