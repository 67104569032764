import {useEffect} from "react";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { TagIcon } from "@heroicons/react/24/outline";
import { LockOpenIcon } from "@heroicons/react/24/outline";
import { BanknotesIcon } from "@heroicons/react/24/outline";
import { ArrowPathRoundedSquareIcon } from "@heroicons/react/24/outline";
import { ShieldExclamationIcon } from "@heroicons/react/24/outline";
import {accountActions} from "../../../redux/actions/account";
import {connect, useSelector} from "react-redux";
import * as Global from "../../biz/Global"
import numeral from "numeral";

const UserLogin = (props) => {
    const {user} = useSelector((props) => props.account);

    const onPoint = () => {

    }

    const onCoupon = () => {

    }

    const logout = () => {
        // 로그인 정보를 없애자
        props.setAccount({})
        props.onMenu(0)
        props.setLogin(false)
    }

    return (
        <div className={"flex flex-row justify-center"}>
            <div />
            <div className={"w-[1144px] bg-black flex flex-row items-center justify-end p-2 gap-3 border-b-2 border-yellow-100"}>
                <span className={"text-sm text-yellow-200"}>{user.nickName}</span>
                <div className={"flex flex-row gap-1 items-center"}>
                    <EnvelopeIcon className="h-4 w-4 text-gray-500" />
                    <span className={"text-sm text-yellow-200"}>0</span>
                </div>
                <button className={"flex flex-row gap-1 items-center"} onClick={() => props.onMenu(Global.MENU_CHARGE)}>
                    <span className={"text-sm text-yellow-200"}>보유금액</span>
                    <span className={"text-sm text-blue-500"}>{numeral(user.userCash).format("0,0")} 원</span>
                </button>
                <button className={"flex flex-row gap-1 items-center"} onClick={onPoint}>
                    <span className={"text-sm text-yellow-200"}>포인트</span>
                    <span className={"text-sm text-blue-500"}>{numeral(user.userPoint).format("0,0")}p</span>
                </button>
                {/*<button className={"flex flex-row gap-1 items-center"} onClick={onCoupon}>*/}
                {/*    <TagIcon className="h-4 w-4 text-gray-500" />*/}
                {/*    <span className={"text-sm text-yellow-200"}>쿠폰관리</span>*/}
                {/*</button>*/}
                <button className={"flex flex-row gap-1 items-center"} onClick={logout}>
                    <LockOpenIcon className="h-4 w-4 text-gray-500" />
                    <span className={"text-sm text-yellow-200"}>로그아웃</span>
                </button>
                <div className={"flex flex-row gap-1"}>
                    <button className={"flex flex-row gap-1 items-center"} onClick={() => props.onMenu(Global.MENU_CHARGE)}>
                        <BanknotesIcon className="h-4 w-4 text-white" />
                        <span className={"text-sm text-yellow-200"}>충전</span>
                    </button>
                    |
                    <button className={"flex flex-row gap-1 items-center"} onClick={() => props.onMenu(Global.MENU_WITHDRAW)}>
                        <ArrowPathRoundedSquareIcon className="h-4 w-4 text-white" />
                        <span className={"text-sm text-yellow-200"}>환전</span>
                    </button>
                    |
                    <button className={"flex flex-row gap-1 items-center"} onClick={() => props.onMenu(Global.MENU_EXCHANGE_POINT)}>
                        <ShieldExclamationIcon className="h-4 w-4 text-white" />
                        <span className={"text-sm text-yellow-200"}>포인트전환</span>
                    </button>
                </div>
            </div>
            <div />
        </div>
    )
}

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        account
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAccount: (account) => {
            dispatch(accountActions.setAccount(account));
        },
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserLogin)