
const Footer = (props) => {
    return (
        <div className={"flex flex-col"}>
            <div className={"flex flex-row justify-center border-t border-yellow-100"}>
                <div />
                <div className={"min-w-[1140px] flex flex-col bg-black p-2"}>
                    <div className={"flex flex-row gap-3"}>
                        <span className={"text-sm text-yellow-200"}>공지사항</span>
                        <span className={"text-sm text-yellow-200"}>자주묻는질문</span>
                        <span className={"text-sm text-yellow-200"}>문의하기</span>
                    </div>
                </div>
            </div>
            <div className={"flex flex-row justify-center border-t border-yellow-100"}>
                <div />
                <div className={"min-w-[1140px] flex flex-col bg-black p-2 gap-2"}>
                    <span className={"text-sm text-yellow-200"}>
                        One Solution 카지노는 세계 최고 수준의 온라인 게임을 제공합니다.
                    </span>
                    <span className={"text-sm text-yellow-200"}>
                        최고 배당의 스포츠 이벤트와 가상게임, 현지에서 느낄 수 있는 사실적인 라이브카지노, 미니게임과 같은 다양한 온라인 게임을 제공합니다.
                    </span>
                    <span className={"text-sm text-yellow-200"}>
                        One Solution 카지노는 게임엔터테인먼트 기업의 선두주자이며, 게임 기술과 보안은 세계 최고 수준이라 자부합니다.
                    </span>
                </div>
                <div />
            </div>
        </div>
    )
}

export default Footer