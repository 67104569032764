import {useEffect, useState} from "react";
import WidgetGameScheduleRow from "./WidgetGameScheduleRow";
import WidgetBoardRow from "./WidgetBoardRow";

const WidgetBoard = (props) => {
    const [isNotice, setIsNotice] = useState(true)

    const toggle = (value) => {
        setIsNotice(value)
    }

    return (
        <div className={"w-[550px] h-[225px] rounded-xl border-2 border-yellow-100 p-2"}>
            <div className={"flex flex-row items-center justify-center"}>
                <button className={`w-[120px] py-2 rounded-full text-sm ${isNotice ? "text-black bg-yellow-200 z-2" : "text-yellow-200 bg-gray-400 z-1"}`}
                        onClick={() => toggle(true)}>공지사항</button>
            </div>
            <div className={"flex flex-col my-4"}>
                {props.list.map((item, idx) => {
                    return <WidgetBoardRow key={String(idx)} data={item} notice={isNotice} />
                })}
            </div>
        </div>
    )
}

export default WidgetBoard