import {changeSportName} from "../../biz/Betting";

const WidgetGameScheduleRow = (props) => {
    const {playing} = props
    const {time, sport, home_name, away_name} = props.data

    return (
        <div className={"flex flex-row p-1 border-b border-yellow-100"}>
            <span className={"w-[100px] text-sm text-center text-white"}>{time.slice(11, 16)}</span>
            <div className={"flex flex-row w-[200px] gap-1 items-center"}>
                <span className={"text-sm text-white"}>{changeSportName(sport)}</span>
                {!playing &&
                <span className={"rounded-full bg-gray-500 px-2 text-xs text-white"}>경기종료</span>
                }
            </div>
            <div className={"flex flex-div w-full gap-2"}>
                <span className={"text-sm text-white"}>{home_name}</span>
                <span className={"text-xs text-blue-500 font-bold"}>vs</span>
                <span className={"text-sm text-white"}>{away_name}</span>
            </div>
        </div>
    )
}

export default WidgetGameScheduleRow