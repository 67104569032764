import {useEffect, useState} from "react";
import * as api from "../../api/api";
import Loading from "../../component/Loading";

const Notice = (props) => {
    const [noticeList, setNoticeList] = useState([])
    const [loading, setLoading] = useState(false)
    const [article, setArticle] = useState({})

    useEffect(() => {
        search()
    }, [])

    const search = () => {
        setLoading(true)
        api.getBoardList(1).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                setNoticeList(data)
            }
            else {
                window.alert(statusText)
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const detail = (notice) => {
        setArticle(notice)
    }

    return (
        <div className={"w-[1140px] flex flex-col bg-white"}>
            {loading ? <Loading/> : null}
            <img src={"https://14-ca.com/img/lang/kr/img_title_cs.jpg"} alt={""}/>
            <div className={"flex flex-row p-2 justify-between"}>
                <div className={"flex flex-row"}>
                    <span className={"text-sm text-gray-500"}>고객센터 >> 공지사항</span>
                </div>
                <div className={"flex flex-row gap-1"}>
                    <input className={"px-2 py-1 border rounded text-sm"}
                           placeholder={"검색"}
                    />
                    <select className={"px-4 py-1 border rounded text-sm"}>
                        <option>전체</option>
                    </select>
                    <button className={"bg-green-800 rounded text-white text-sm font-bold px-4"} onClick={search}>검색</button>
                </div>
            </div>
            <div className={"flex flex-col p-2"}>
                <div className={"flex flex-row bg-gray-400 gap-2"}>
                    <span className={"w-[88px] py-2 text-sm text-center"}>No</span>
                    <span className={"w-[88px] py-2 text-sm text-center"}>종류</span>
                    <span className={"w-[593px] py-2 text-sm text-center"}>제목</span>
                    <span className={"w-[220px] py-2 text-sm text-center"}>등록일</span>
                </div>
                {noticeList.map((notice, idx) => {
                    return (
                        <div key={String(idx)} className={"flex flex-row p-2"} onClick={() => detail(notice)}>
                            <span className={"w-[88px] text-sm text-center"}>{notice.seq}</span>
                            <span className={"w-[88px] text-sm text-center"}>공지사항</span>
                            <span className={"w-[593px] text-sm text-center"}>{notice.subject}</span>
                            <span className={"w-[220px] text-sm text-center"}>{notice.instTime}</span>
                        </div>
                    )})
                }
            </div>
        </div>
    )
}

export default Notice