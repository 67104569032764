/**
 * 배당율 계산 함수
 */
export const calcOdds = (bettingList) => {
    return (bettingList.reduce((acc, curr) => acc * curr.odd_value, 1)).toFixed(2)
}

/**
 * 경기명 치환
 */
export const changeSportName = (sportCode) => {
    let sportName = ""
    switch (sportCode) {
        case 'soccer':
            sportName = "축구"
            break;
        case 'esports':
            sportName = "E스포츠"
            break;
        case 'baseball':
            sportName = "야구"
            break;
        case 'basketball':
            sportName = "농구"
            break;
        case 'volleyball':
            sportName = "배구"
            break;
        case 'tennis':
            sportName = "테니스"
            break;
        case 'tabletennis':
            sportName = "탁구"
            break;
        case 'icehockey':
            sportName = "하키"
            break;
        case 'americanfootball':
            sportName = "미식축구"
            break;
        case 'boxingufc':
            sportName = "UFC/복싱"
            break;
        case 'handball':
            sportName = "핸드볼"
            break;
        default:
            sportName = sportCode
            break;
    }
    return sportName
}