import React, {useEffect, useState} from "react";
import ic_close from "../../../assets/ic-close.svg";
import * as api from "../../../api/api";
import {connect, useSelector} from "react-redux";

const PointExchange = (props) => {
    const {user} = useSelector((props) => props.account)
    const [exchange, setExchange] = useState(0)

    const close = () => {
        props.onClose();
    }

    const onExchange = () => {
        if (exchange === 0) {
            window.alert("전환할 포인트를 입력하세요.")
            return
        }

        const request = {
            userId: user.userId,
            exchangeType: "C",
            amount: exchange
        }

        if (window.confirm("포인트 전환을 하시겠어요?")) {
            api.requestExchangePoint(request).then(result => {
                const {data, status, statusText} = result
                if (status === 200) {
                    window.alert("정상적으로 처리되었습니다.")
                    props.onClose()
                }
                else {
                    window.alert(statusText)
                }
            })
                .catch(ex => {
                    window.alert(ex.message)
                })
                .finally(() => {

                })
        }
    }

    return (
        <div className={"bg-gray-800 py-[30px] rounded"}>
            <div className={"flex flex-row justify-end px-[36px]"} onClick={close}>
                <img src={ic_close} alt={""}/>
            </div>
            <p className={"text-white text-center text-4xl font-bold"} onClick={close}>{props.title}</p>

            <div className={"flex flex-col px-8 mt-8 gap-2"}>
                <div className={"flex flex-row text-white text-sm items-center gap-2"}>
                        <span className="w-[100px] font-bold text-gray-200">
                            전환 포인트
                        </span>
                    <input
                        id="amount"
                        name="amount"
                        value={exchange}
                        onChange={event => {
                            setExchange(event.target.value)
                        }}
                        required
                        className="rounded border-0 px-4 py-1.5 text-end text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
                <button className={"rounded mt-4 p-2 bg-blue-500 text-lg text-white font-bold"}
                        onClick={onExchange}>
                    포인트 전환
                </button>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const {account, store} = state;
    return {
        account, store
    }
}

export default connect(mapStateToProps, null)(PointExchange);