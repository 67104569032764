import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const RollingBanner = (props) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000
    }

    return (
        <div className={"w-[770px] h-[352px] bg-red-500 border-2 border-yellow-100"}>
            <Slider {...settings}>
                <img src={"https://14-ca.com/themes/14Casino/img/kr/slide1.png"} alt={""}/>
                <img src={"https://14-ca.com/img/winner-mgld-desktop.jpg"} alt={""}/>
                <img src={"https://14-ca.com/img/winner-desktop-mgbc.jpg"} alt={""}/>
                <img src={"https://14-ca.com/img/winner_desktop_ns.jpg"} alt={""}/>
                <img src={"https://14-ca.com/img/winner-mgs-desktop.jpg"} alt={""}/>
                <img src={"https://14-ca.com/img/slide2.png"} alt={""}/>
                <img src={"https://14-ca.com/img/winner_desktop_ezugi.png"} alt={""}/>
                <img src={"https://14-ca.com/img/winner_wm2_desktop.png"} alt={""}/>
                <img src={"https://14-ca.com/img/winner_desktop-sg.png"} alt={""}/>
                <img src={"https://14-ca.com/img/winner-play-and-go-desktop.jpg"} alt={""}/>
                <img src={"https://14-ca.com/img/win_desktop_bng2.png"} alt={""}/>
                <img src={"https://14-ca.com/img/win_desktop_fs.jpg"} alt={""}/>
                <img src={"https://14-ca.com/img/win_desktop_mg2.jpg"} alt={""}/>
                <img src={"https://14-ca.com/img/win_desktop-pp-new.jpg"} alt={""}/>
            </Slider>
        </div>
    )
}

export default RollingBanner