import {connect} from "react-redux";
import {useState} from "react";

const Customer = (props) => {
    const [searchWhere, setSearchWhere] = useState({
        userId: "", searchValue: "", category: ""
    })
    const [list, setList] = useState([])

    return (
        <div className={"w-[1140px] flex flex-col bg-white"}>
            <img src={"https://14-ca.com/img/lang/kr/img_title_cs.jpg"} alt={""}/>
            <div className={"flex flex-row p-2 justify-between"}>
                <div className={"flex flex-row"}>
                    <span className={"text-sm text-gray-500"}>고객센터 >> 1:1문의</span>
                </div>
                <div className={"flex flex-row gap-1"}>
                    <input className={"px-2 py-1 border rounded text-sm"}
                           placeholder={"검색"}
                    />
                    <select className={"px-4 py-1 border rounded text-sm"}>
                        <option>전체</option>
                    </select>
                    <button className={"bg-green-800 rounded text-white text-sm font-bold px-4"}>검색</button>
                </div>
            </div>
            <div className={"flex flex-col p-2"}>
                <div className={"flex flex-row bg-gray-400 gap-2"}>
                    <span className={"w-[88px] py-2 text-sm text-center"}>확인</span>
                    <span className={"w-[88px] py-2 text-sm text-center"}>종류</span>
                    <span className={"w-[593px] py-2 text-sm text-center"}>제목</span>
                    <span className={"w-[110px] py-2 text-sm text-center"}>상태</span>
                    <span className={"w-[132px] py-2 text-sm text-center"}>등록일</span>
                    <span className={"w-[88px] py-2 text-sm text-center"}>삭제</span>
                </div>
            </div>
            <div className={"flex flex-row justify-end p-2"}>
                <div className={"flex flex-row gap-1"}>
                    <button className={"rounded bg-gray-500 px-4 py-1 text-white text-sm"}>전체 선택</button>
                    <button className={"rounded bg-gray-500 px-4 py-1 text-white text-sm"}>선택 삭제</button>
                    <button className={"rounded bg-gray-500 px-4 py-1 text-white text-sm"}>계좌 문의</button>
                    <button className={"rounded bg-gray-500 px-4 py-1 text-white text-sm"}>문의 작성</button>

                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        account
    }
}

export default connect(mapStateToProps, null)(Customer)