import logo from "../../../assets/logo.png"
import { MegaphoneIcon } from "@heroicons/react/16/solid";

const Marquee = ({ text }) => {
    return (
        <div className={"marquee flex-1"}>
            <div className={"marquee-content text-sm font-bold text-yellow-200"}>
                {text}
            </div>
        </div>
    )
}

const Header = (props) => {
    return (
        <div className={"flex flex-col items-center"}>
            <div className={"w-[1140px] flex flex-row items-center justify-between gap-2"}>
                <MegaphoneIcon className="h-4 w-4 text-white" />
                <Marquee text={"◆◆ 입금하시기전에는 항상 계좌문의 하시고 이용해주시기 바랍니다 ◆◆"} />
                <span className={"text-sm"}>총판페이지</span>
            </div>
            <div className={"w-[1140px] p-2 flex flex-row bg-black items-center justify-between gap-10"}>
                <img className={"w-[165px] text-white"} src={logo} alt={""} onClick={() => props.onMenu(0)} />
                <div className={"flex flex-row gap-2"}>
                    <button className={"px-4 py-2"}>
                        <span className={"text-yellow-100 text-sm font-bold hover:text-red-500"} onClick={() => props.onMenu(1)}>조합배팅</span>
                    </button>
                    <button className={"px-4 py-2"}>
                        <span className={"text-yellow-100 text-sm font-bold hover:text-red-500"} onClick={() => props.onMenu(2)}>고객센터</span>
                    </button>
                    {/*<button className={"px-4 py-2"}>*/}
                    {/*    <span className={"text-yellow-100 text-sm font-bold hover:text-red-500"} onClick={() => props.onMenu(3)}>게시판</span>*/}
                    {/*</button>*/}
                    <button className={"px-4 py-2"}>
                        <span className={"text-yellow-100 text-sm font-bold hover:text-red-500"} onClick={() => props.onMenu(4)}>배팅내역</span>
                    </button>
                    <button className={"px-4 py-2"}>
                        <span className={"text-yellow-100 text-sm font-bold hover:text-red-500"} onClick={() => props.onMenu(5)}>배팅규정</span>
                    </button>
                    <button className={"px-4 py-2"}>
                        <span className={"text-yellow-100 text-sm font-bold hover:text-red-500"} onClick={() => props.onMenu(6)}>공지사항</span>
                    </button>
                </div>
            </div>
            <div className={"w-full h-0.5 bg-yellow-100"}/>
        </div>
    )
}

export default Header;