import React from 'react'

const Loading = (props) => {
    return (
        <div className={"gap-2"}
             style={{
                position: 'absolute',
                background: '#000000b7',
                zIndex: 999,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh'
        }}>
            <svg className="bg-white animate-spin h-10 w-10 mr-3 ..." viewBox="0 0 24 24" />
        </div>
    )
}

export default Loading
