import {useEffect, useState} from "react";
import Loading from "../../component/Loading";
import FreeMatch from "./FreeMatch";

const Contents = ({props, title, onCart}) => {
    const [loading, setLoading] = useState(false);
    return (
        <div className={"px-4 overflow-y-auto"}>
            {loading ? <Loading /> : null}
            <FreeMatch props={props} onCart={onCart} />
        </div>
    )
}

export default Contents