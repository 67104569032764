import {useEffect, useState} from "react";
import RollingBanner from "./widget/RollingBanner";
import MenuButton from "./widget/MenuButton";
import WidgetGameSchedule from "./widget/WidgetGameSchedule";
import WidgetWeekWithdraw from "./widget/WidgetWeekWithdraw";
import WidgetBoard from "./widget/WidgetBoard";
import {accountActions} from "../../redux/actions/account";
import {connect} from "react-redux";
import * as Global from "../biz/Global"
import * as api from "../../api/api";
import Loading from "../../component/Loading";
import {getBoardList} from "../../api/api";

const Main = (props) => {
    const [loading, setLoading] = useState(false)
    const [nowSports, setNowSports] = useState([])
    const [noticeList, setNoticeList] = useState([])

    useEffect(() => {
        getNowSports()
        getBoardList()
    }, [])

    const getNowSports = () => {
        setLoading(true)
        api.getSportsNow().then(result => {
            const {status, statusText, data} = result;
            if (status === 200) {
                setNowSports(data)
            }
        })
            .catch(ex => {

            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getBoardList = () => {
        api.getBoardList(1).then(result => {
            const {status, statusText, data} = result;
            if (status === 200) {
                setNoticeList(data)
            }
        })
            .catch(ex => {
                console.error(ex.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div className={"flex flex-col gap-4 p-3"}>
            {loading ? <Loading /> : null}
            <div className={"flex flex-row gap-2 justify-between"}>
                <RollingBanner />
                <div className={"grid grid-cols-2 gap-2"}>
                    <MenuButton id={Global.MENU_CHARGE} title={"보유머니 충전"} desc={"MONEY CHANGE"} img={"https://14-ca.com/img/main_quick1.png"} onClick={props.onMenu} />
                    <MenuButton id={Global.MENU_WITHDRAW} title={"보유머니 환전"} desc={"MONEY EXCHANGE"} img={"https://14-ca.com/img/main_quick2.png"} onClick={props.onMenu} />
                    <MenuButton id={Global.MENU_EXCHANGE_POINT} title={"포인트 전환"} desc={"POINT EXCHANGE"} img={"https://14-ca.com/img/main_quick3.png"} onClick={props.onMenu} />
                    <MenuButton id={Global.MENU_BETTING_RULE} title={"게임가이드"} desc={"GAME GUIDE"} img={"https://14-ca.com/img/main_quick4.png"} onClick={props.onMenu} />
                    <MenuButton id={Global.MENU_ATTENDANCE} title={"출석부"} desc={"ATTENDANCE"} img={"https://14-ca.com/img/main_quick5.png"} onClick={props.onMenu} />
                    <MenuButton id={Global.MENU_BETTING_HISTORY} title={"경기결과"} desc={"GAME RESULT"} img={"https://14-ca.com/img/main_quick6.png"} onClick={props.onMenu} />
                </div>
            </div>
            <div className={"flex flex-row gap-2 justify-between"}>
                <WidgetGameSchedule list={nowSports} />
                <WidgetWeekWithdraw />
            </div>
            <div className={"flex flex-row gap-2 justify-between"}>
                <WidgetBoard list={noticeList} />
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAccount: (account) => {
            dispatch(accountActions.setAccount(account));
        },
        dispatch
    }
}

export default connect(null, mapDispatchToProps)(Main)