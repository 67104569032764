import {useNavigate} from "react-router-dom";
import logo from "../../assets/logo.png"
import backMovie from "../../assets/movie_1.mp4"
import sha256 from "sha256";
import * as api from "../../api/api";
import {useState} from "react";
import {accountActions} from "../../redux/actions/account";
import {connect} from "react-redux";

const Login2 = (props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [loginInfo, setLoginInfo] = useState({userId: "", password: ""})

    const accept = () => {
        const {userId, password} = loginInfo;
        if (userId.length === 0 || password.length === 0) {
            return;
        }

        const params = {
            userId: userId,
            // password: sha256(password)
            password: password
        }

        setLoading(true);
        api.login(params).then(result => {
            const {data, status, statusText} = result;
            if (status === 200) {
                // 사용자 정보 확인
                if (data.useYn === 1) {
                    if (data.status === 9) {
                        // 아직 가입 대기상태
                        window.alert("회원 가입 대기상태 입니다. 고객센터에 문의하세요.")
                        return
                    }
                }
                else {
                    window.alert("사용할 수 없는 계정입니다. 고객센터에 문의하세요.")
                }

                // 로그인 타입 확인
                props.setAccount(data);
                navigate("/")
            }
            else {
                window.alert(statusText);
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const register = () => {
        navigate("/register")
    }

    return (
        <div className={"relative h-screen w-full overflow-hidden"}>
            <video className={"absolute top-0 left-0 w-full h-full object-cover"}
                   src={backMovie}
                   autoPlay={true}
                   loop={true}
                   muted={true}/>
            <div className="absolute top-0 left-0 w-full h-full bg-black opacity-75" />
            <div className={"relative z-10 flex flex-row gap-4 h-screen items-center justify-center"}>
                <div className={"flex flex-col gap-4 items-center"}>
                    <img className={"w-[178px] h-[68px]"} src={logo} alt={""}/>
                    <input className={"bg-transparent border-b border-gray-500 text-white px-4 py-2"}
                           placeholder={"아이디"}
                           value={loginInfo.userId}
                           onChange={event => {
                               setLoginInfo((state) => ({
                                   ...state, userId: event.target.value
                               }))
                           }}
                           onKeyPress={(e) => {
                               if (e.key === "Enter") {
                                   accept()
                               }
                           }}
                    />
                    <input className={"bg-transparent border-b border-gray-500 text-white px-4 py-2"}
                           type={"password"}
                           placeholder={"비밀번호"}
                           value={loginInfo.password}
                           onChange={event => {
                               setLoginInfo((state) => ({
                                   ...state, password: event.target.value
                               }))
                           }}
                           onKeyPress={(e) => {
                               if (e.key === "Enter") {
                                   accept()
                               }
                           }}
                    />
                    <div className={"flex flex-row gap-4 items-center justify-center"}>
                        <button className={"rounded-full border-2 border-red-500 px-4 py-1 text-red-500"} onClick={register}>가입신청</button>
                        <button className={"rounded-full border-2 border-gray-500 px-4 py-1 text-gray-500"} onClick={accept}>로그인</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAccount: (account) => {
            dispatch(accountActions.setAccount(account));
        },
        dispatch
    }
}

export default connect(null, mapDispatchToProps)(Login2)