import {useEffect, useState} from "react";
import WidgetGameScheduleRow from "./WidgetGameScheduleRow";

const WidgetGameSchedule = (props) => {
    const [scrolling, setScrolling] = useState(false);
    const [isPlaying, setIsPlaying] = useState(true)
    const [sports, setSports] = useState([])

    useEffect(() => {
        // 경기 목록 필터링, 상위 20개만
        let filterList;
        if (isPlaying) {
            // 일단 경기중인거 필터링
            filterList = props.list.filter(item => item.status_id === 0 || item.status_id === 1)
        }
        else {
            // 경기 끝난거
            filterList = props.list.filter(item => item.status_id === 2)
        }
        setSports(filterList)
    }, [props, isPlaying])

    useEffect(() => {
        const scrollInterval = setInterval(() => {
            setScrolling(prev => !prev);
        }, 10000); // Adjust the duration as needed (20 seconds for a complete cycle)

        return () => clearInterval(scrollInterval);
    }, []);

    const toggle = (value) => {
        setIsPlaying(value)
    }

    return (
        <div className={"w-[550px] h-[450px] rounded-xl border-2 border-yellow-100 p-2"}>
            <div className={"flex flex-row items-center gap-4"}>
                <span className={"font-bold text-xl text-yellow-200"}>경기 일정 및 결과</span>
                <div className={"flex flex-row items-center justify-center"}>
                    <button className={`w-[120px] py-2 rounded-full text-sm ${isPlaying ? "text-black bg-yellow-200 z-2" : "text-yellow-200 bg-gray-400 z-1"}`}
                            onClick={() => toggle(true)}>경기 예정</button>
                    <button className={`-ml-7 w-[120px] py-2 rounded-full text-sm ${!isPlaying ? "text-black bg-yellow-200 z-2" : "text-yellow-200 bg-gray-400 z-1"}`}
                            onClick={() => toggle(false)}>경기종료</button>
                </div>
            </div>
            <div className={"flex flex-col my-4"}>
                <div className={"flex flex-row py-1 bg-gray-800"}>
                    <span className={"w-[100px] text-sm text-center text-white"}>경기시간</span>
                    <span className={"w-[150px] text-sm text-white"}>종목</span>
                    <span className={"text-sm text-white"}>vs</span>
                </div>
                <div className={"scroll-container1"}>
                    <div className={`scroll-content1 ${scrolling ? 'scrolling' : ''}`}>
                        {sports.map((item, idx) => {
                            return <WidgetGameScheduleRow key={String(idx)} data={item} playing={isPlaying} />
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WidgetGameSchedule